"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __NgCli_locale_1 = require("@angular/common/locales/de");
var __NgCli_locale_2 = require("@angular/common");
__NgCli_locale_2.registerLocaleData(__NgCli_locale_1.default);
var core_1 = require("@angular/core");
var environment_1 = require("./environments/environment");
var __NgCli_bootstrap_1 = require("./app/app.module.ngfactory");
var __NgCli_bootstrap_2 = require("@angular/platform-browser");
if (environment_1.environment.production) {
    core_1.enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, {
    preserveWhitespaces: true
});
